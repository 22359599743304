a {
    color: #0275d8;
}

body {
    font-family: 'Roboto', sans-serif;
    height: 100%;
    width: 100%;
}

hr {
    margin: 5px 0px;
}

html {
    height: 100%;
    width: 100%;
    font-size: 16px;
}

textarea {
    resize: none;
}

/* ---------------------------------------------------
    ALERT STYLE
--------------------------------------------------- */
.dgdg-alert {
    font-family: Calibri;
    font-size: small;
    position: fixed;
    width: 33%;
    margin: 10px 25%;
    z-index: 9999;
}

/* ---------------------------------------------------
    BODY STYLE
--------------------------------------------------- */
.dgdg-root {
    height: 100%;
    width: 100%;
}

.dgdg-site {
    height: calc(100% - 24px);
    width: 100%;
}

.dgdg-site-no-footer {
    min-width: 320px;
    height: 100%;
}

.dgdg-site-collapsed {
    width: calc(100% - 160px);
    height: 100%;
}

.dgdg-site-expanded {
    width: calc(100% - 40px);
    height: 100%;
}

.dgdg-site-body {
    padding: 5px;
    font-family: Calibri;
    font-size: small;
    height: 100%;
    width: 100%;
}

.dgdg-site-content {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.dgdg-site-scrollable-content {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.dgdg-site-scrollable-content .card {
    margin-right: 5px;
    margin-bottom: 5px;
}

.dgdg-signin {
    margin: auto;
}

.dgdg-signin a {
    width: 332px;
    font-size: 20px !important;
    height: 50px !important;
    line-height: 40px;
    font-weight: bold;
}

.google-visualization-tooltip {
    margin-left: -20px;
    margin-top: 20px;
}

/* ---------------------------------------------------
    BOOTSTRAP OVERRIDES
--------------------------------------------------- */
ol {
    margin-bottom: 0px !important;
}

table .form-group {
    margin-bottom: 0px !important;
}

.alert {
    padding: 2px 10px 25px 10px;
}

.badge {
    padding: 8px 10px;
}

.breadcrumb {
    font-size: 12px !important;
    padding: 2px 15px;
    margin-bottom: 0px;
}

.breadcrumb .btn {
    padding: 0px;
}

.breadcrumb .breadcrumb-item {
    display: flex;
    align-items: center;
}

.btn {
    font-size: clamp(0.313rem, 2vw, 0.813rem);
}

.btn:focus,
.btn:active {
    box-shadow: none;
}

.btn-secondary {
    color: #292b2c;
    background-color: #fff;
    border-color: #ccc;
}

.btn-link {
    border: none !important;
    color: #0275d8;
}

.card {
    border: none;
}

.card-block {
    margin: 5px;
}

.container-fluid {
    padding: 5px 5px 0px 5px;
}

.col-form-label {
    padding-top: 0px;
    padding-bottom: 0px;
}

.custom-switch {
    padding-left: 30px !important;
}

.custom-switch .custom-control-label::after {
    background-color: black;
}

.custom-control-input:disabled~.custom-control-label::after {
    border-color: #adb5bd;
}

.custom-file-label::after {
    top: 1px !important;
    background-color: #007bff;
    color: white;
}

.input-group-append {
    height: 36px;
}

.dropdown .btn-secondary:focus {
    border-color: rgb(206, 212, 218);
    box-shadow: none;
}

.dropdown .form-control:focus {
    border-color: rgb(206, 212, 218);
    box-shadow: none;
}

.dropdown.show .dropdown-toggle {
    border-bottom-right-radius: 0px;
}

.dropdown.show .form-control {
    border-bottom-left-radius: 0px;
}

.dropdown-item {
    color: #212529;
    font-size: 13px;
    padding: 3px 8px;
    line-height: 19.5px;
}

.dropdown-menu {
    min-width: 10px;
}

.dropdown-range-control {
    width: 150px;
}

.dropdown-header {
    padding: 8px;
}

.dropdown-header td {
    padding: 0px 8px;
    line-height: 18px;
}

.dropdown-toggle::after {
    margin-left: 8px;
    margin-top: 6px;
}

.dropdown-dgdg-color-box {
    display: inline-block;
    margin-right: 5px;
    width: 20px;
}

.fade {
    -webkit-transition: opacity .5s linear;
    -o-transition: opacity .5s linear;
    transition: opacity .5s linear
}

.form-control {
    font-family: Calibri;
    font-size: 13px !important;
}

.form-inline .col-form-label {
    padding-right: 5px;
}

.form-inline .form-group {
    margin: 5px 0px;
}

.form-inline .form-row>.btn {
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-inline label {
    justify-content: flex-start;
}

.form-row {
    width: 100%;
}

.form-row .sk-cube-grid {
    margin-top: 10px;
}

.input-group input {
    height: 36px !important;
}

.input-group-text {
    font-family: Calibri;
    font-size: 13px !important;
}

input,
.input-group-append .btn {
    font-size: clamp(0.438rem, 0.903vw, 0.813rem) !important;
    padding: clamp(0.125rem, 0.417vw, 0.375rem) clamp(0.25rem, 0.833vw, 0.75rem) !important;
}

.modal-open .modal {
    overflow: auto !important;
}

.modal-dialog {
    max-width: 100%;
}

.navbar-nav {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 0px;
}

.navbar-nav .nav-link {
    padding: 0px 10px !important;
}

.nav-pills a {
    color: black;
}

.nav-pills .btn-link {
    color: black;
    margin: 0.2em;
}

.nav-pills .btn-dark {
    margin: 0.2em;
}

/* ---------------------------------------------------
    CHART STYLE
--------------------------------------------------- */
.axis path,
.axis line {
    fill: none;
    stroke: black;
    shape-rendering: crispEdges;
}

.axis text {
    font-size: 10px;
}

.dgdg-azure-map-custom-info-box {
    max-width: 200px;
    padding: 15px;
    max-width: 200px;
}

.dgdg-azure-map-custom-info-box .name {
    font-size: 20px;
    font-weight: bold;
}

.dgdg-azure-map-legend {
    position: absolute;
    left: 10px;
    background-color: #FFFFFF;
    border: 1px solid black;
    border-radius: 5px;
    width: 125px;
    padding: 10px;
    font-size: 10px;
    z-index: 999;
}

.dgdg-azure-map-legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.label {
    fill: #000;
}

/* .legend {
    position: absolute;
    left: 10px;
    top: 70px;
    background-color: #FFFFFF;
    border: 1px solid black;
    border-radius: 5px;
    width: 125px;
    padding: 10px;
    font-size: 10px;
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
} */

.tooltip {
    display: inline-block;
    position: relative;
    text-align: left;
    opacity: 1;
    z-index: unset;
}

.tooltip .content {
    transform: translate(0, 10px);
    padding: 5px;
    color: #666666;
    background-color: #FFFFE0;
    font-weight: normal;
    font-size: 14px;
    border-radius: 8px;
    position: absolute;
    z-index: 2000;
    box-sizing: border-box;
    display: none;
    border: 1px solid #DCA;
}

.tooltip:hover .content {
    display: block;
}

/* ---------------------------------------------------
    COMMON STYLE
--------------------------------------------------- */
.dgdg-badge {
    background-color: #2E77AE;
    margin-bottom: 5px;
    font-size: clamp(0.313rem, 2vw, 0.813rem);
}

.dgdg-badge-margin {
    margin-right: 5px;
}

.dgdg-badge-margin .dgdg-badge {
    margin-bottom: 0px !important;
}

.dgdg-card-gutter {
    margin-bottom: 10px;
}

.dgdg-card-gutter:last-child {
    margin-bottom: 0px;
}

.dgdg-tab {
    margin-bottom: 10px !important;
}

.dgdg-nav {
    margin-bottom: 10px !important;
}

.dgdg-bg-red {
    background-color: red;
}

.dgdg-border-bottom {
    border-bottom: 1px solid black !important;
}

.dgdg-border-bottom-white {
    border-bottom: 1px solid white !important;
}

.dgdg-border-dotted-top {
    border-top: 1px dotted black !important;
}

.dgdg-border-dotted-bottom {
    border-bottom: 1px dotted black !important;
}

.dgdg-border-left {
    border-left: 1px solid black !important;
}

.dgdg-border-right {
    border-right: 1px solid black !important;
}

.dgdg-border-top {
    border-top: 1px solid black !important;
}

.dgdg-border-thick-bottom {
    border-bottom: 2px solid black !important;
}

.dgdg-border-thick-left {
    border-left: 2px solid black !important;
}

.dgdg-border-thick-right {
    border-right: 2px solid black !important;
}

.dgdg-border-thick-top {
    border-top: 2px solid black !important;
}

.dgdg-box-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dgdg-carousel-completed {
    color: darkgreen;
    background-color: green;
}

.dgdg-carousel-completed .dgdg-carousel-inner {
    background-color: lightgreen;
}

.dgdg-carousel-inner {
    border: none none none;
    border-radius: 50%;
    display: inline-block;
    height: 40%;
    position: absolute;
    right: 30%;
    top: 30%;
    width: 40%;
}

.dgdg-carousel-line {
    width: calc(50% - 4px);
    margin-bottom: 9px;
    border-width: 3px;
}

.dgdg-carousel-line-left {
    margin-left: -7px;
}

.dgdg-carousel-line-right {
    margin-right: -7px;
}

.dgdg-carousel-none {
    border-width: 0px !important;
}

.dgdg-carousel-outer {
    border: none none none;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    position: relative;
    width: 20px;
}

.dgdg-carousel-pending {
    background-color: lightgray;
}

.dgdg-carousel-pending .dgdg-carousel-inner {
    background-color: darkgray;
}

.dgdg-copy-button {
    margin-left: 15px;
}

.dgdg-copy-textbox {
    position: absolute;
    top: -9999px;
}

.dgdg-dropdown-menu {
    z-index: 2000;
    right: 0px !important;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin: 0px 0px;
    max-height: 300px;
    overflow: auto;
}

.dgdg-flex {
    display: flex;
}

.dgdg-flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.dgdg-flex-column {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.dgdg-flex-item {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 40px;
}

.dgdg-font-black {
    color: #000000;
}

.dgdg-font-blue {
    color: #1F3D78;
}

.dgdg-font-green {
    color: green;
}

.dgdg-font-grey {
    color: grey;
}

.dgdg-font-navy {
    color: navy;
}

.dgdg-font-red {
    color: red;
}

.dgdg-font-red a {
    color: red !important;
}

.dgdg-font-white {
    color: #FFFFFF;
}

.dgdg-font-yellow {
    color: #ffd000;
}

.dgdg-font-size-14 {
    font-size: 14px !important;
}

.dgdg-font-size-18 {
    font-size: 18px !important;
}

.dgdg-font-underline {
    text-decoration: underline dotted black;
}

.dgdg-font-underline:hover {
    text-decoration: underline dotted black;
}

.dgdg-full-screen {
    height: 100%;
}

.dgdg-full-width {
    width: 100%;
}

.dgdg-icon {
    margin-left: 15px !important;
}

.dgdg-modal-dialog {
    width: 500px;
    margin: 200px auto;
}

.dgdg-multi-select {
    right: 0px !important;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin: 0px 0px;
    max-height: 300px;
    overflow: auto;
}

.dgdg-multiselect-item {
    align-items: center;
}

.dgdg-multiselect-item input[type="checkbox"] {
    margin-right: 5px;
}

.dgdg-no-padding {
    padding: 0px !important;
}

.dgdg-no-border {
    border: none !important;
}

.dgdg-no-border-bottom {
    border-bottom: none !important;
}

.dgdg-no-border-left {
    border-left: none !important;
}

.dgdg-no-border-right {
    border-right: none !important;
}

.dgdg-no-border-top {
    border-top: none !important;
}

.dgdg-no-padding-bottom {
    padding-bottom: 0px !important;
}

.dgdg-page-title {
    padding: 10px;
    font-size: 18px;
}

.dgdg-query {
    align-items: center;
}

.dgdg-query :not(:first-child) {
    text-indent: 0px;
}

.dgdg-query-toggle {
    position: absolute;
    right: 0px;
}

.dgdg-quick-link~.dgdg-quick-link {
    /*Except first occurence*/
    margin-left: 15px;
}

.dgdg-quick-link-bg {
    background-color: #FFFFFF;
}

.dgdg-space-border {
    border-right: 1px !important;
    border-right-style: solid !important;
    border-left: 1px !important;
    border-left-style: solid !important;
}

.dgdg-table-filter-text {
    margin-top: -6px;
    font-size: 13px;
}

.dgdg-table-filter {
    font-size: clamp(0.313rem, 0.694vw, 0.625rem) !important;
}

.dgdg-text-vertical {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    white-space: nowrap;
    width: 20px;
    margin: 0px !important;
}

.dgdg-min-width-100 {
    min-width: 100px !important;
}

.dgdg-width-100 {
    width: 100px !important;
}

.dgdg-width-50 {
    width: 50px !important;
}

.dgdg-zero-border-right {
    border-right: 0 !important;
}

/* ---------------------------------------------------
    SCROLL OVERRIDES
--------------------------------------------------- */
.sidebar::-webkit-scrollbar {
    width: 7px;
}

.sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    border: 2px solid white;
}

.dgdg-reports-card::-webkit-scrollbar,
.dgdg-reports .card-block::-webkit-scrollbar,
.dgdg-site-scrollable-content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.dgdg-reports-card::-webkit-scrollbar-track,
.dgdg-reports .card-block::-webkit-scrollbar-track,
.dgdg-site-scrollable-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

.dgdg-reports-card::-webkit-scrollbar-thumb,
.dgdg-reports .card-block::-webkit-scrollbar-thumb,
.dgdg-site-scrollable-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 2px solid white;
}

.dgdg-reports-card::-webkit-scrollbar-corner,
.dgdg-reports .card-block::-webkit-scrollbar-corner,
.dgdg-site-scrollable-content::-webkit-scrollbar-corner {
    display: none;
}

/* ---------------------------------------------------
    DATEPICKER OVERRIDES
--------------------------------------------------- */
.ui-datepicker {
    z-index: 1100 !important;
}

.ui-datepicker table {
    font-size: 13px !important;
}

.ui-priority-secondary {
    opacity: 1 !important;
    font-weight: 700 !important;
}

.ui-widget {
    font-size: 15px !important;
}

.ui-widget-header {
    border: #2E77AE !important;
    background: #2E77AE !important;
}

.dgdg-store-width {
    width: 375px !important;
}

.dgdg-sl-width {
    width: 20px !important;
}


/* ---------------------------------------------------
    FOOTER STYLE
--------------------------------------------------- */
.dgdg-footer-container {
    font-family: Calibri;
    font-size: clamp(0.438rem, 0.903vw, 0.813rem) !important;
    background-color: #195DE6;
    color: white;
    padding-left: 10px;
    padding-right: 6px;
    width: 100%;
    min-width: 320px;
}

.dgdg-footer-container .btn {
    height: 24px !important;
    font-size: clamp(0.438rem, 0.903vw, 0.813rem) !important;
}

.dgdg-console-log-dialog {
    width: 1230px;
    margin: 50px auto;
    font-family: Calibri;
    font-size: small;
}

.dgdg-console-log-props {
    background-color: green;
    color: white;
}

.dgdg-console-log-props td {
    background-color: green !important;
    color: white;
}

.dgdg-console-log-state {
    background-color: blue;
    color: white;
}

.dgdg-console-log-state td {
    background-color: blue !important;
    color: white;
}

.dgdg-console-log-roles {
    background-color: orange;
    color: white;
}

.dgdg-console-log-roles td {
    background-color: orange !important;
    color: white;
}

/* ---------------------------------------------------
    HEADER STYLE
--------------------------------------------------- */
.dgdg-logo {
    height: 57px;
}

.dgdg-header {
    width: 100%;
}

.dgdg-header-right-content {
    padding-right: 6px;
    font-size: 12px !important;
}

.dgdg-menu-profile {
    background-color: dimgray;
    color: lightgray;
    text-align: center;
}

div[data-flag-red="true"] {
    padding: 0px 10px;
    background-color: red;
}

div[data-flag-red="true"]>.btn-link {
    color: white !important;
}

/* ---------------------------------------------------
    IMPORT WIZARD STYLE
--------------------------------------------------- */
.dgdg-import-wizard-dialog {
    width: 1240px;
    margin: 50px auto;
}

.dgdg-import-wizard-legend-container {
    width: 150px;
}

.dgdg-import-wizard-legend {
    padding: 2px 10px;
    margin: 0px 0px 0px 20px;
    border: lightgray solid 1px;
}

.dgdg-import-wizard-verify-table {
    max-height: 170px;
    overflow: auto;
}

.dgdg-import-wizard-good-col {
    background-color: #FFFFFF;
}

.dgdg-import-wizard-invalid-col {
    background-color: #ffff64;
}

.dgdg-import-wizard-missing-col {
    background-color: red;
}

.dgdg-import-wizard-ignored-col {
    background-color: #AEAAAA;
    color: #0275d8;
}

/* ---------------------------------------------------
    INPUT STYLE
--------------------------------------------------- */
input[type=file]:disabled+label {
    color: #6c757d;
}

.dgdg-button-width {
    width: clamp(4.375rem, 11.111vw, 10rem) !important;
}

.dgdg-comment {
    width: 500px !important;
}

.dgdg-date {
    width: 140px !important;
}

.dgdg-date-buttons {
    width: 210px !important;
}

.dgdg-dropdown-v2 {
    display: inline-block;
    width: calc(100% - 25px);
    border-bottom: 1px solid black;
    padding: 5px;
    margin: 5px;
}

.dgdg-label {
    padding: 7px 10px;
}

.dgdg-form-label {
    padding-left: 5px;
}

.dgdg-input input {
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.dgdg-input input:hover {
    border-right: none;
}

.dgdg-input input:active {
    border-right: none;
}

.dgdg-input input:focus {
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.dgdg-input button {
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom-right-radius: 0px;
    background-color: white;
}

.dgdg-input button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dgdg-input-file-button-only {
    width: 40px;
}

.dgdg-input-file-color {
    color: #0275d8;
    padding-top: 10px;
}

.dgdg-money {
    width: 125px !important;
}

.dgdg-percent {
    width: 125px !important;
}

.dgdg-toggle-box-button {
    padding: 0px !important
}

.dgdg-toggle-box-span {
    margin-left: 10px !important
}

/* ---------------------------------------------------
    MODAL STYLE
--------------------------------------------------- */
.dgdg-modal-dialog-header {
    line-height: clamp(1.25rem, 2.292vw, 2.063rem);
}

.dgdg-modal-dialog-header .btn-link {
    padding: clamp(0.063rem, 0.348vw, 0.313rem) !important;
}

.dgdg-modal-dialog-header>span:first-child {
    vertical-align: -webkit-baseline-middle;
}

/* ---------------------------------------------------
    PHOTO STYLE
--------------------------------------------------- */
.dgdg-dot {
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    display: inline-block;
}

.dgdg-dot .active {
    background-color: #0275d8;
}

.dgdg-photo-dots-span {
    text-align: center;
    margin-top: 10px
}

/* ---------------------------------------------------
    PROGRESS STYLE
--------------------------------------------------- */
.sk-cube-grid {
    width: 20px;
    height: 20px;
    margin: 5px 5px;
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #2E77AE;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {

    0%,
    70%,
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    35% {
        -webkit-transform: scale3d(0, 0, 1);
        transform: scale3d(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {

    0%,
    70%,
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    35% {
        -webkit-transform: scale3d(0, 0, 1);
        transform: scale3d(0, 0, 1);
    }
}

.sk-cube-center {
    margin: 5px auto;
}

/* ---------------------------------------------------
    REPORT STYLE
--------------------------------------------------- */
.dgdg-report-body {
    height: 100%;
    width: 100%;
    background-color: #2E77AE;
    border-radius: 5px;
    padding: 10px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
--------------------------------------------------- */
.collapse {
    display: none !important;
}

.collapse.show {
    display: block !important;
}

.sidebar {
    font-size: 12px !important;
    min-width: 40px;
    background: #f8f8f8;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.sidebar a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}

.sidebar .btn-link {
    font-family: sans-serif;
    padding: 0px;
    text-align: left;
    width: 100%;
}

.sidebar .btn-link,
.btn-link:hover,
.btn-link:focus {
    color: inherit !important;
    text-decoration: none !important;
}

.sidebar ul li a {
    padding: 10px;
    display: block;
}

.sidebar ul li a:hover {
    color: #218DEF;
    background: #f8f8f8;
}

.sidebar ul li a i {
    margin-right: 5px;
}

.sidebar ul li .btn-link {
    padding: 10px;
    display: block;
}

.sidebar ul li .btn-link:hover {
    color: #218DEF;
    background: #f8f8f8;
}

.sidebar ul li .btn-link i {
    margin-right: 5px;
}

.sidebar ul ul a {
    padding-left: 15px !important;
    background: #efefef;
}

.sidebar ul ul .btn-link {
    padding-left: 15px !important;
    background: #efefef;
}

.sidebar.active {
    width: 40px;
}

.sidebar.active ul li a i {
    display: block;
}

.sidebar.active>ul>li>a>span {
    display: none;
}

.sidebar.active ul li .btn-link i {
    display: block;
}

.sidebar.active>ul>li>.btn-link>span {
    display: none;
}

.sidebar.active ul li {
    position: relative;
    display: inline-block;
    width: 100%;
}

.sidebar.active ul li ul {
    position: absolute;
    z-index: 1;
    left: 42px;
    top: 0px;
    width: 200px;
    z-index: 2000;
}

.sidebar.active ul ul a {
    padding: 8px !important;
}

.sidebar.active ul ul .btn-link {
    padding: 8px !important;
}

.sidebar ul li.active>a {
    font-weight: bold;
    color: #218DEF;
    background: #efefef;
}

.sidebar ul li.active>.btn-link {
    font-weight: bold;
    color: #218DEF;
    background: #efefef;
}

.sidebar-menu-icon {
    width: 16px;
    text-align: center;
}

/* ---------------------------------------------------
    TABLE STYLE
--------------------------------------------------- */
.dgdg-column-band-1 {
    background-color: #99D9EA !important;
}

.dgdg-column-band-2 {
    background-color: #FFFFFF !important;
}

.dgdg-column-gap {
    background-color: #FFFFFF !important;
    padding: 0px 2px !important;
    max-width: 5px;
    width: 5px;
}

.dgdg-row-bg-head-foot {
    background-color: #AEAAAA;
    color: #0275d8;
}

.dgdg-table-footer-seperator {
    background-color: #2E77AE;
    height: 10px;
}

.dgdg-table-debug {
    border: 1px solid red;
}

.dgdg-table-debug td {
    border: 1px solid red !important;
}

.dgdg-table-legend {
    padding-left: 50px;
}

/* ---------------------------------------------------
    TABLE STYLE V3
--------------------------------------------------- */
.dgdg-table-v3 {
    border-radius: 5px;
    width: 100%;
}

.dgdg-table-v3 td {
    padding: 5px 6px;
}

.dgdg-table-v3 td>.btn-link {
    padding: 0px;
}

.dgdg-table-v3-demo {
    border-radius: 5px;
    width: 100%;
}

.dgdg-table-v3-demo td {
    padding: 5px;
}

.dgdg-table-v3-demo td>.btn-link {
    padding: 0px;
}

.dgdg-table-v3-row {
    border-top: 1px solid #DCDCDC;
}

.dgdg-table-v3-row td {
    background-color: #FFF;
}

.dgdg-table-v3-row:hover {
    position: relative;
    box-shadow: 0 -2px 2px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 20%);
}

.dgdg-table-v3-row:hover td {
    position: relative;
    filter: contrast(90%);
}

.dgdg-table-v3-row td:hover {
    filter: none;
}

.dgdg-table-v3-header {
    background-color: #FAFAFA;
}

.dgdg-table-v3-header td:not(:last-child) {
    border-right: 1px solid #DCDCDC;
}

.dgdg-table-v3 .sk-cube-grid {
    width: 12px;
    height: 12px;
    margin: 3px 3px;
    vertical-align: middle;
    display: inline-block;
}


/* ---------------------------------------------------
    WIDGET STYLE
--------------------------------------------------- */
.dgdg-widget-item {
    width: 100%;
    height: 100%;
}

.dgdg-widget-item-new {
    width: 100%;
    height: 100%;
    border: 1px solid black;
}

.dgdg-widget-maximized {
    height: 100%;
    width: 100%;
    padding: 5px;
}

.dgdg-widget-restored {
    height: 300px;
    padding: 10px;
}

/* ---------------------------------------------------
   BAD DEBT STYLE
--------------------------------------------------- */
.dgdg-bad-debt-content {
    min-width: 440px;
}

.dgdg-bad-debt-detail-comment-text-area {
    width: 400px;
}

.dgdg-bad-debt-detail-journal-entry-details-dialog {
    width: 1000px;
    margin: 100px auto;
}

.dgdg-bad-debt-detail-status {
    min-width: 160px;
}

.dgdg-bad-debt-posting-date-col {
    min-width: 150px;
}

.dgdg-bad-debt-status-bg-default td {
    background-color: lightblue;
}

.dgdg-bad-debt-status-bg-other {
    background-color: lightblue;
}

.dgdg-bad-debt-status-bg-other-gt-60 {
    background-color: red;
}

.dgdg-bad-debt-status-bg-other-gt-60 td {
    background-color: lightcoral;
}

.dgdg-bad-debt-status-bg-other-gt-60-legend {
    background-color: lightcoral;
}

.dgdg-bad-debt-status-bg-other-le-60 {
    background-color: #ffff64;
}

.dgdg-bad-debt-status-bg-other-le-60 td {
    background-color: #ffff64;
}

.dgdg-bad-debt-status-bg-paid-value {
    background-color: lightgreen;
}

.dgdg-bad-debt-status-bg-paid-value td {
    background-color: lightgreen;
}

.dgdg-bad-debt-status-bg-total {
    background-color: #000000;
}

.dgdg-bad-debt-legend-container {
    padding-top: 6px;
    white-space: nowrap;
}

.dgdg-bad-debt-status-bg-head {
    background-color: #CECCCC;
}

.dgdg-bad-debt-status-bg-total-service-nsf {
    background-color: #c6e0b4;
}

.dgdg-bad-debt-status-bg-total-service-nsf td {
    background-color: #c6e0b4;
}

/* ---------------------------------------------------
    CACHE STYLE
--------------------------------------------------- */
.dgdg-client-cache-value {
    width: 200px;
}

.dgdg-client-cache-key {
    width: 180px;
}

.dgdg-cache-head-redis-color {
    background-color: #c6e0b4 !important;
}

.dgdg-cache-head-memory-color {
    background-color: #b4c6e7 !important;
}

/* ---------------------------------------------------
   OPENRO STYLE
--------------------------------------------------- */

.dgdg-open-ro-hide-completed {
    width: 95px;
}

.dgdg-open-ro-doc {
    background-color: #ffff64 !important;
}

.dgdg-open-ro-status-col {
    min-width: 205px;
}

.dgdg-open-ro-status-in-process {
    background-color: #FFFFFF;
}

.dgdg-open-ro-status-in-process td {
    background-color: #FFFFFF;
}

.dgdg-open-ro-status-holding-parts {
    background-color: lightcoral;
}

.dgdg-open-ro-status-holding-parts td {
    background-color: lightcoral;
}

.dgdg-open-ro-status-holding-parts-backorder {
    background-color: orange;
}

.dgdg-open-ro-status-holding-parts-backorder td {
    background-color: orange;
}

.dgdg-open-ro-status-holding-authorization {
    background-color: lightblue;
}

.dgdg-open-ro-status-holding-authorization td {
    background-color: lightblue;
}

.dgdg-open-ro-status-other {
    background-color: #edb879;
}

.dgdg-open-ro-status-other td {
    background-color: #edb879;
}

.dgdg-open-ro-status-completed {
    background-color: lightgreen;
}

.dgdg-open-ro-status-completed td {
    background-color: lightgreen;
}

.dgdg-open-ro-est-completion-date-passed {
    background-color: #ffff64;
}

.dgdg-open-ro-est-completion-date-passed td {
    background-color: #ffff64;
}

.dgdg-open-ro-widget-maximized {
    height: 600px;
}

.dgdg-open-ro-widget-restored {
    height: 300px;
}

/* ---------------------------------------------------
   CIT STYLE
--------------------------------------------------- */
.dgdg-cit-content {
    min-width: 440px;
}

.dgdg-cit-detail-journal-entry-details-dialog {
    width: 1100px;
    margin: 100px auto;
}

.dgdg-cit-detail-journal-entry-details-dialog .card {
    width: 100%;
}

.dgdg-cit-detail-journal-entry-details-dialog .dgdg-report-body {
    display: inline-grid;
    overflow: auto;
}

.dgdg-cit-detail-status-not-funded {
    background-color: #FFFFFF;
}

.dgdg-cit-detail-status-not-funded td {
    background-color: #FFFFFF;
}

.dgdg-cit-detail-status-funded {
    background-color: lightgreen;
}

.dgdg-cit-detail-status-funded td {
    background-color: lightgreen;
}

.dgdg-cit-detail-status-fail {
    background-color: lightcoral;
}

.dgdg-cit-detail-status-fail td {
    background-color: lightcoral;
}

.dgdg-cit-detail-status-potential-fail {
    background-color: #fffdd0;
}

.dgdg-cit-detail-status-potential-fail td {
    background-color: #fffdd0;
}

.dgdg-cit-detail-status-missing-down-payment {
    background-color: #fffdd0;
}

.dgdg-cit-detail-status-missing-down-payment td {
    background-color: #fffdd0;
}

.dgdg-cit-detail-status-need-clean-air-rebate {
    background-color: #fffdd0;
}

.dgdg-cit-detail-status-need-clean-air-rebate td {
    background-color: #fffdd0;
}

.dgdg-cit-detail-status-rollback {
    background-color: lightblue;
}

.dgdg-cit-detail-status-rollback td {
    background-color: lightblue;
}

.dgdg-cit-detail-status-out-for-repo {
    background-color: #edb879;
}

.dgdg-cit-detail-status-out-for-repo td {
    background-color: #edb879;
}

.dgdg-cit-detail-status-factory-buy-back {
    background-color: #D0A5FC;
}

.dgdg-cit-detail-status-factory-buy-back td {
    background-color: #D0A5FC;
}

.dgdg-cit-detail-status-holding-funds {
    background-color: #ffff64;
}

.dgdg-cit-detail-status-holding-funds td {
    background-color: #ffff64;
}

.dgdg-cit-detail-status-short-funded {
    background-color: #fffdd0;
}

.dgdg-cit-detail-status-short-funded td {
    background-color: #fffdd0;
}

.dgdg-cit-detail-status-down-payment-not-posted {
    background-color: #fffdd0;
}

.dgdg-cit-detail-status-down-payment-not-posted td {
    background-color: #fffdd0;
}

.dgdg-cit-legend-container {
    padding-top: 6px;
    white-space: nowrap;
}

.dgdg-cit-posting-date-col {
    min-width: 135px;
}

.dgdg-cit-summary-gt-30 {
    background-color: red;
}

.dgdg-cit-summary-gt-20 {
    background-color: #00ccff;
}

.dgdg-cit-summary-gt-10 {
    background-color: #ffff64;
}

.dgdg-cit-summary-gt-5 {
    background-color: #99ff66;
}

.dgdg-cit-summary-total {
    background-color: #99ccff;
}

/* ---------------------------------------------------
    SERVICE SCHEDULE STYLE
--------------------------------------------------- */
.dgdg-service-schedule-legend-container {
    padding-top: 6px;
    white-space: nowrap;
}

.dgdg-service-schedule-status-needs-additional-documentation {
    background-color: #cd5c5c;
}

.dgdg-service-schedule-status-needs-additional-documentation td {
    background-color: #cd5c5c;
}

.dgdg-service-schedule-status-paid-short-over-adjust {
    background-color: #ffff64;
}

.dgdg-service-schedule-status-paid-short-over-adjust td {
    background-color: #ffff64;
}

.dgdg-service-schedule-status-paid-in-full {
    background-color: lightgreen;
}

.dgdg-service-schedule-status-paid-in-full td {
    background-color: lightgreen;
}

.dgdg-service-schedule-status-awaiting-check-payment {
    background-color: #edb879;
}

.dgdg-service-schedule-status-awaiting-check-payment td {
    background-color: #edb879;
}

.dgdg-service-schedule-status-awaiting-credit-card-payment {
    background-color: #edb879;
}

.dgdg-service-schedule-status-awaiting-credit-card-payment td {
    background-color: #edb879;
}

.dgdg-service-schedule-status-awaiting-assistance-from-oem {
    background-color: #edb879;
}

.dgdg-service-schedule-status-awaiting-assistance-from-oem td {
    background-color: #edb879;
}

.dgdg-service-schedule-status-pending-factory-review {
    background-color: #edb879;
}

.dgdg-service-schedule-status-pending-factory-review td {
    background-color: #edb879;
}

.dgdg-service-schedule-summary {
    display: inline-grid;
}

.dgdg-service-schedule-summary-gap {
    margin-bottom: 15px !important;
}

.dgdg-service-schedule-ro-detail-spinner {
    margin: auto;
}

/* ---------------------------------------------------
    CONTROLLER POLICY STYLE
--------------------------------------------------- */
.dgdg-controller-policy-ddp {
    width: 380px;
}

/* ---------------------------------------------------
    COVID STYLE
--------------------------------------------------- */
.dgdg-covid {
    position: relative;
    width: 830px;
    font-size: 20px !important;
}

.dgdg-covid-check {
    width: 830px;
    margin-top: -200px;
}

.dgdg-covid-field {
    width: 270px;
}

.dgdg-covid-footer {
    position: absolute;
    width: 450px;
    bottom: 0px;
    right: 0px;
}

.dgdg-covid-header {
    width: 900px;
}

.dgdg-covid-status-body {
    background-color: #fbbb5d;
}

.dgdg-covid-status-header {
    background-color: #005291;
    color: white;
}

.dgdg-covid-submit {
    height: 100px;
    font-size: 20px;
    text-align: center;
    padding: 250px;
}

.dgdg-covid-table {
    border-collapse: separate;
    margin: 0px 50px;
}

.dgdg-covid-table td {
    border: solid #bd8d46 1px;
    padding: 5px 20px;
}

/* ---------------------------------------------------
    CSI STYLE
--------------------------------------------------- */
.dgdg-csi-bg-modified {
    background-color: #ffff64;
}

.dgdg-csi-dialog {
    width: 1150px;
    margin: 200px auto;
}

.dgdg-csi-sales-color {
    background-color: #c6e0b4 !important;
}

.dgdg-csi-service-color {
    background-color: #b4c6e7 !important;
}

/* ---------------------------------------------------
   DEAL ISSUES STYLE
--------------------------------------------------- */
.dgdg-deal-issues-col-status {
    min-width: 125px !important;
}

.dgdg-deal-issues-col-type {
    min-width: 100px !important;
}

.dgdg-deal-issues-content {
    min-width: 375px;
}

.dgdg-deal-issues-add-dialog {
    width: 1020px;
    min-width: 400px;
    margin: 200px auto;
}

.dgdg-deal-issues-table {
    width: 100%;
}

.dgdg-deal-issues-table td {
    padding: 3px 0px;
}

.dgdg-deal-issues-action-margin {
    margin-left: 8px !important;
}

.dgdg-deal-issues-action-columnHelp-width {
    width: 140px;
}

.dgdg-deal-issues-status-bg-resolved {
    background-color: lightgreen;
}

.dgdg-deal-issues-status-bg-resolved td {
    background-color: lightgreen;
}

.dgdg-deal-issues-status-bg-closed {
    background-color: rgb(192, 192, 192);
}

.dgdg-deal-issues-status-bg-closed td {
    background-color: rgb(192, 192, 192);
}

.dgdg-deal-issues-summary-number-column {
    text-align: center;
}


/* ---------------------------------------------------
    DEMO STYLE
--------------------------------------------------- */
.dgdg-demo-page-content {
    min-width: 320px;
}

.dgdg-demo-action-column {
    min-width: 90px;
}

.dgdg-demo-bg-head {
    background-color: #CECCCC;
}

.dgdg-demo-bg-modified {
    background-color: #ffff64;
}

.dgdg-demo-detail-widget-restored {
    height: 450px;
    padding: 0px 0px 0px 10px;
    flex-grow: 1;
}

.dgdg-demo-page-edit-dialog {
    width: 1000px;
    min-width: 600px;
    margin: 150px auto;
}

.dgdg-demo-page-glyphs-dialog {
    width: 500px;
    margin: 150px auto;
}

.dgdg-demo-page-powerbi-report-window {
    height: 100%;
    width: 100%;
}

.dgdg-demo-page-data-event-cancel-btn {
    padding: 0px !important;
    height: 10px !important;
}

/* ---------------------------------------------------
   SELL TO DGDG STYLE
--------------------------------------------------- */
.dgdg-sell-trade-api-response-dialog {
    width: 1000px;
    margin: 100px auto;
}

.dgdg-sell-trade-autocheck-table-result {
    margin-top: 20px;
}

.dgdg-sell-trade-autocheck-table-result tr td {
    border: 1px solid black;
    padding: 10px;
    background-color: #BDD7EE;
    font-weight: bold;
}


.dgdg-sell-trade-autocheck-table-result-disabled {
    margin-top: 20px;
}

.dgdg-sell-trade-autocheck-table-result-disabled tr td {
    border: 1px solid black;
    padding: 10px;
    background-color: lightgray;
    font-weight: bold;
}

.dgdg-sell-trade-autocheck-water-mark {
    font-size: 15px;
    margin-top: 30px;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    transform: rotate(-45deg);
    width: 135px;
}

.dgdg-sell-trade-calculation-dialog {
    width: 820px;
    margin: 10px auto;
}

.dgdg-sell-trade-offer-number-column {
    width: 200px;
}

.dgdg-sell-trade-email-column {
    min-width: 120px;
}

.dgdg-sell-trade-offer-copy-btn {
    padding: 0px 4px !important;
    height: 19px !important;
}

.dgdg-sell-trade-offer-calculation-disabled {
    background-color: #808080;
}

.dgdg-sell-trade-offer-calculation-disabled td {
    background-color: #808080;
}

.dgdg-sell-trade-offer-calculation-general-adjustment-yellow {
    background-color: #ffff64 !important;
}

.dgdg-sell-trade-final {
    width: 100%;
}

.dgdg-sell-trade-final td {
    padding: 3px 0px;
}

.dgdg-sell-trade-final-dialog {
    width: 750px;
    max-width: 10px auto;
}

.dgdg-sell-trade-header {
    font-weight: bold;
    text-decoration: underline;
}

.dgdg-sell-trade-manheim-table-result {
    margin-top: 20px;
}

.dgdg-sell-trade-manheim-table-result tr td {
    border: 1px solid black;
    padding: 10px;
    background-color: #BDD7EE;
    font-weight: bold;
}

.dgdg-sell-trade-manheim-table-result-disabled {
    margin-top: 20px;
}

.dgdg-sell-trade-manheim-table-result-disabled tr td {
    border: 1px solid black;
    padding: 10px;
    background-color: lightgray;
    font-weight: bold;
}

.dgdg-sell-trade-manheim-water-mark {
    font-size: 30px;
    margin-top: 50px;
    opacity: 0.5;
    position: absolute;
    text-align: center;
    transform: rotate(-45deg);
    width: 255px;
}

.dgdg-sell-trade-table-result {
    margin-top: 20px;
}

.dgdg-sell-trade-table-result tr td {
    border: 1px solid black;
    padding: 10px;
    background-color: #BDD7EE;
    font-weight: bold;
}

.dgdg-sell-trade-kbb-evox-action-col {
    min-width: 110px;
}

.dgdg-sell-trade-kbb-evox-comment-col {
    min-width: 125px;
}

.dgdg-sell-trade-kbb-evox-save-dialog {
    width: 1150px;
    margin: 200px auto;
}

.dgdg-sell-trade-photo-water-mark {
    font-size: 30px;
    opacity: 0.5;
    transform: rotate(-45deg);
    padding: 175px;
}

.dgdg-sell-trade-selected-image-water-mark {
    font-size: 30px;
    opacity: 0.5;
    transform: rotate(-45deg);
    padding: 100px;
}

.dgdg-sell-trade-thumbnail-water-mark {
    font-size: 10px;
    opacity: 0.5;
    transform: rotate(-45deg);
    margin: 20px 0px 0px 15px;
    width: 100%;
}

.dgdg-sell-trade-kbb-evox-image {
    border: 1px solid black;
    margin: auto;
    height: 320px;
    width: 426px;
    margin-block: 5px;
}

.dgdg-sell-trade-kbb-evox-image-status {
    min-width: 140px;
}

.dgdg-sell-trade-kbb-evox-image-label {
    width: 550px;
    justify-content: center !important;
}

.dgdg-sell-trade-kbb-evox-oem-color {
    border: lightgray solid 1px;
    height: 25px;
    width: 25px;
    margin: 3px;
}

.dgdg-sell-trade-kbb-evox-default-color {
    border: yellow solid 2px !important;
}

.dgdg-sell-trade-kbb-evox-oem-colors {
    text-align: left;
    border-right: 2px solid black;
    height: 99px;
    width: 230px;
    margin-bottom: 5px;
}

.dgdg-sell-trade-kbb-evox-thumbnail {
    display: inline-block;
}

.dgdg-sell-trade-kbb-evox-thumbnail-image {
    text-align: left;
    border: 1px solid black;
    height: 100px;
    width: 133px;
    padding: 2px;
    margin-bottom: 5px;
}

.dgdg-sell-trade-kbb-ymmt-col {
    min-width: 300px;
}

.dgdg-sell-trade-evox-ymmt-col {
    min-width: 500px;
}

.dgdg-sell-trade-offer-salesforce-link {
    margin-top: 7px !important;
}

.dgdg-sell-trade-offer-raw-view-dialog {
    width: 1000px;
    margin: 150px auto;
}

.dgdg-sell-trade-offer {
    width: 100%;
}

.dgdg-sell-trade-offer tr td {
    padding: 10px;
    border: 1px solid black;
}

.dgdg-sell-trade-vo-alert {
    padding: 5px !important;
    font-size: 10px;
    display: inline;
}

.dgdg-sell-trade-offer-detail {
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
}

.dgdg-sell-trade-offer-detail-header {
    color: #0275d8;
}

.dgdg-sell-trade-offer-detail td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    white-space: nowrap;
}

.dgdg-sell-trade-offer-detail-show-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dgdg-sell-trade-offer-source {
    background-color: lightblue !important;
}

.dgdg-sell-trade-offer-source td {
    background-color: lightblue !important;
}

.dgdg-sell-trade-offer-confirm {
    background-color: lightgreen;
}

.dgdg-sell-trade-offer-confirm td {
    background-color: lightgreen;
}

.dgdg-sell-trade-offer-error {
    background-color: lightcoral;
}

.dgdg-sell-trade-offer-error td {
    background-color: lightcoral;
}

.dgdg-sell-trade-offer-warn {
    background-color: #fffdd0;
}

.dgdg-sell-trade-offer-warn td {
    background-color: #fffdd0;
}

.dgdg-sell-trade-offer-expired {
    background-color: lightgray;
}

.dgdg-sell-trade-offer-expired td {
    background-color: lightgray;
}

.dgdg-sell-trade-offer-legend-container {
    padding-top: 6px;
    white-space: nowrap;
}

.dgdg-sell-trade-radio-button-conditional-text {
    color: #0082EC;
    padding-left: 38px !important;
}

/* ---------------------------------------------------
   EVOX IMAGES
--------------------------------------------------- */

.evox-images-separator {
    border-right: 1px solid #DCDCDC;
}

.evox-images-kbb-evox-photos-dialog {
    width: 700px;
    margin: 150px auto;
}

.evox-images-bg-modified {}

.evox-images-bg-modified td {
    background-color: #ffff64;
}

.evox-images-inactive-mapping {
    background-color: lightcoral;
}

.evox-images-inactive-mapping td {
    background-color: lightcoral;
}

.evox-images-hidden {
    background-color: lightcoral;
}

.evox-images-hidden td {
    background-color: lightcoral;
}

.evox-images-debug {
    background-color: black;
    color: white;
    padding: 4px 10px 4px 10px;
    margin-top: 5px;
    text-align: center;
}

.evox-images-oem-colors-debug {
    width: 227px;
    border-right: 2px solid white;
    display: inline-block;
}

.evox-images-oem-colors-raw-view-dialog {
    width: 680px;
    margin: 200px auto;
}

.evox-images-oem-colors-raw-view-btn {
    padding: 0px;
    max-width: 200px;
    font-size: 10px !important;
    height: 20px !important;
}

.evox-images-thumbnail-debug {
    display: inline-block;
}

.evox-images-thumbnail-debug-body {
    width: 133px;
    padding: 2px;
    font-size: 10px !important;
}

/* ---------------------------------------------------
    SALESFORCE USER
--------------------------------------------------- */

.dgdg-salesforce-user-detail {
    font-size: 12px !important;
    width: 100% !important;
}

.dgdg-salesforce-user-detail-header {
    color: #0275d8;
}

.dgdg-salesforce-user-detail td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    white-space: nowrap;
}

/* ---------------------------------------------------
    ACTIVE DIRECTORY USER
--------------------------------------------------- */
.dgdg-activedirectory-user-content {
    min-width: 350px;
}

.dgdg-activedirectory-user-activedirectory-color {
    background-color: #c6e0b4 !important;
}

.dgdg-activedirectory-user-palocity-color {
    background-color: #b4c6e7 !important;
}

.dgdg-activedirectory-user-cell-font {
    background-color: red !important;
    color: white !important
}

.dgdg-activedirectory-user-principal-name-width {
    max-width: 200px !important;
}

.dgdg-activedirectory-logon-email-width {
    max-width: 150px !important;
}

/* ---------------------------------------------------
    NON SALES EMPLOYEE TIME TRACK STYLE
--------------------------------------------------- */
.dgdg-non-sales-employee-time-track-bg-pink {
    background-color: #F8696B;
}

.dgdg-non-sales-employee-time-track-bg-red {
    background-color: #880808;
}

.dgdg-non-sales-employee-time-track-bg-orange {
    background-color: #FFA500;
}

.dgdg-non-sales-employee-time-track-bg-yellow {
    background-color: #FFFF00;
}

/* ---------------------------------------------------
    EMPLOYEE TIME TRACK STYLE
--------------------------------------------------- */
.dgdg-employee-time-track-content {
    min-width: 460px !important;
}

.dgdg-employee-time-track-dialog {
    width: 550px;
    margin: 350px auto;
}

.dgdg-employee-time-track-bg-head {
    background-color: #F8F8F8;
}

.dgdg-employee-time-track-table {}

.dgdg-employee-time-track-table .dgdg-table tr td {
    border-right: 1px solid black;
}

.dgdg-employee-time-track-table .dgdg-table tr td:last-child {
    border-right: none;
}

.dgdg-employee-time-track-table .dgdg-table tbody tr td a {
    color: black;
    text-decoration: underline;
}

/* ---------------------------------------------------
    FI BANK PARTNERSHIP STYLE
--------------------------------------------------- */
.dgdg-fi-bank-partner-bg-head {
    background-color: #CECCCC;
}

/* ---------------------------------------------------
    SERVICE MANAGER PAYROLL
--------------------------------------------------- */
.dgdg-service-manager-payroll {
    display: inline-grid;
}

.dgdg-service-manager-secondary-payroll {
    display: inline-block !important
}

.dgdg-service-manager-payroll .card-block {
    overflow: auto !important;
}

.dgdg-service-manager-payroll-details-dialog {
    width: 1000px;
    margin: 50px auto;
}

.dgdg-service-manager-payroll-gap {
    margin-bottom: 15px !important;
}

/* ---------------------------------------------------
    FI PAYROLL STYLE
--------------------------------------------------- */
.dgdg-fi-payroll-detail-dialog {
    width: 1450px;
    margin: 200px auto;
}

.dgdg-fi-payroll-extra-col {
    background-color: #AEAAAA !important;
    color: #0275d8;
}

.dgdg-fi-payroll-missing-col {
    background-color: red !important;
}

.dgdg-fi-payroll-plan-table-td-width {
    width: 45px;
}

.dgdg-fi-payroll-plan-table-textbox-width {
    width: 120px;
}

.dgdg-fi-payroll-plan-dialog {
    width: 1063px;
    margin: 250px auto;
}

.dgdg-fi-payroll-plan-td-border {
    border-right: 1px solid #ddd;
}

.dgdg-fi-payroll-plan-table {
    width: 100%;
}

.dgdg-fi-payroll-plan-table tr td {
    padding: 6px 6px;
}

.dgdg-fi-payroll-plan-detail-table {
    width: 100%;
}

.dgdg-fi-payroll-plan-font {
    font-weight: bold;
}

.dgdg-fi-payroll-employee-action-column {
    text-align: center;
}

.dgdg-fi-payroll-add-employee-column {
    width: 475px !important;
}

.dgdg-fi-payroll-add-store-column {
    width: 200px !important;
}

.dgdg-fi-payroll-add-employee-position-code-column {
    width: 166px !important;
}

.dgdg-fi-payroll-employee-add-dialog {
    width: 1285px;
    margin: 200px auto;
}

.dgdg-fi-payroll-plan2-dialog {
    width: 1500px;
    margin: 100px auto;
}

.dgdg-fi-payroll-plan2-pvr-edit {
    width: 135px !important;
}

/* ---------------------------------------------------
    FI STORE STYLE
--------------------------------------------------- */
.dgdg-fi-store-report-content {
    min-width: 360px;
}

.dgdg-fi-store-report-top-10-manager {
    height: 420px;
    padding: 10px;
}

.dgdg-fi-store-report-top-10-manager .card-block {
    overflow-x: auto;
}

.dgdg-fi-store-report-drilldown-dialog {
    width: 930px;
    margin: 40px auto;
}

.dgdg-fi-store-dropdown {
    min-width: clamp(0.625rem, 15.278vw, 13.75rem) !important;
}

.dgdg-fi-store-vehicle-type {
    width: clamp(0.625rem, 12.5vw, 11.25rem) !important;
}

/* ---------------------------------------------------
    FINANCIAL STATEMENT STYLE
--------------------------------------------------- */
.dgdg-fs-bg-brown {
    background-color: #c4bd97;
}

.dgdg-fs-bg-black {
    background-color: #000000;
}

.dgdg-fs-bg-green {
    background-color: #92d050;
}

.dgdg-fs-bg-green-oem-1 {
    background-color: #ebf1de;
}

.dgdg-fs-bg-green-oem-2 {
    background-color: #c4d79b;
}

.dgdg-fs-bg-green-oem-3 {
    background-color: #76933c;
}

.dgdg-fs-bg-green-oem-used {
    background-color: #c4d79b;
}

.dgdg-fs-bg-sl-no-col {
    background-color: #FFFFFF;
}

.dgdg-fs-bg-total-diff {
    background-color: #ffff64;
}

.dgdg-fs-detail-dialog {
    width: 1000px;
    margin: 50px auto;
}

.dgdg-fs-detail-home-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    border-radius: 50% 50%;
}

/*
.dgdg-fs-dev-text-red {
    color: brown;
}
*/

.dgdg-fs-heading-1-bg-color {
    background-color: #ffc000;
}

.dgdg-fs-heading-2-bg-color {
    background-color: #c4d79b;
}

.dgdg-fs-table {
    width: 100%;
    font-family: Arial;
    font-size: 13px;
}

.dgdg-fs-table td {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 2px 5px;
}

.dgdg-fs-table-pad-left-1 {
    padding-left: 20px !important;
}

.dgdg-fs-table-pad-left-2 {
    padding-left: 40px !important;
}

.dgdg-fs-value {
    text-align: right;
    color: blue;
    font-size: 12px;
}

.dgdg-fs-value .btn {
    padding: 0px 0px !important;
    -webkit-user-select: auto !important;
    /* Safari */
    -ms-user-select: auto !important;
    /* IE 10 and IE 11 */
    user-select: auto !important;
    /* Standard syntax */
}

.dgdg-fsad-detail-dialog {
    width: 1000px;
    margin: 100px auto;
}

.dgdg-fin-stmt-detail-dialog {
    overflow: auto;
}

.dgdg-fin-stmt-badge {
    margin-left: 10px;
    background-color: yellow;
}

/* ---------------------------------------------------
   FRONT-END ASJUSTMENT STYLE
--------------------------------------------------- */
.dgdg-front-end-adjustment-content {
    min-width: 420px !important;
}

.dgdg-front-end-account-detail-dialog {
    width: 1200px;
    min-width: 600px;
    margin: 100px auto;
}

.dgdg-front-end-table {
    width: 100%;
}

.dgdg-front-end-table-label {
    padding-left: 30px !important;
}

.dgdg-front-end-table td {
    padding: 3px 0px;
}

.dgdg-front-end-table-comment .input-group {
    width: auto !important;
}

.dgdg-front-end-gl-bg-total-diff {
    background-color: #ffff64 !important;
}

.dgdg-front-end-print-content tr td {
    border-bottom: 2px solid #ddd;
}

.dgdg-front-end-print-content tr td {
    border-bottom: 2px solid #ddd;
}

.dgdg-front-end-add-reason-dropodown-height {
    max-height: 400px !important;
}

/* ---------------------------------------------------
    LENDER REPORT STYLE
--------------------------------------------------- */
.dgdg-lender-report-new-color {
    background-color: #f8cbad !important;
}

.dgdg-lender-report-used-color {
    background-color: #c6e0b4 !important;
}

.dgdg-lender-report-total-color {
    background-color: #b4c6e7 !important;
}

/* ---------------------------------------------------
    MANUAL CHECK STYLE
--------------------------------------------------- */
.dgdg-manual-check-dialog {
    width: 1200px;
    margin: 250px auto;
}

/* ---------------------------------------------------
    MOBILE MENU STYLE
--------------------------------------------------- */
.dgdg-desktop {
    display: inherit;
}

.dgdg-mobile {
    display: none;
}

.dgdg-mobile-menu-main {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    top: 50px;
}

.dgdg-mobile-menu-main .nav-item {
    font-size: clamp(0.313rem, 2vw, 0.75rem);
}

@media (max-width: 821px) {
    .dgdg-desktop {
        display: none;
    }

    .dgdg-mobile {
        display: inherit;
    }

    .dgdg-site-collapsed {
        width: 100% !important;
    }

    .dgdg-site-expanded {
        width: 100% !important;
    }

    .dgdg-copy-button {
        margin-left: 5px;
        padding: 6px 12px !important;
    }

    .dgdg-table-legend {
        padding: 0px !important;
    }

    .dgdg-icon {
        margin-left: 5px;
    }

    .dgdg-page-title {
        font-size: 13px;
    }

    .dgdg-site-no-footer {
        height: calc(100% - 56px);
    }

    .dgdg-used-reconditioning-table .input-group {
        min-width: 40px !important;
    }

    .dgdg-used-reconditioning-table .dgdg-button-width {
        width: 100px !important;
    }

    .dgdg-used-reconditioning-table .dropdown-menu {
        min-width: 120px;
    }

    .dgdg-used-reconditioning-service-out {
        width: 250px !important;
        min-width: 250px !important;
    }

    .dgdg-used-recon-dialog-edit .input-group {
        min-width: 30px;
    }

    .input-group {
        min-width: 60px;
    }

    .btn-link {
        min-width: 0px !important;
    }

    .btn {
        padding: 5px;
        min-width: 30px;
    }

    .navbar {
        padding-right: 0px;
    }

    .navbar-toggler {
        border: none;
        font-size: 14px;
        padding: 4px 0px;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0,0,0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    }

    .nav-link {
        line-height: 1.5;
        padding: 8px 16px;
    }

    .dgdg-used-reconditioning-edit td:nth-child(2) {
        color: #0275d8 !important;
    }

    .dgdg-used-reconditioning-edit-label {
        text-align: center;
        font-weight: bold;
    }

    .dgdg-used-reconditioning-estimated-date {
        line-height: 2;
    }

    .form-inline .col-form-label {
        padding-right: 3px !important;
    }

    .dgdg-used-reconditioning-label {
        font-size: 10px !important;
    }

    .dgdg-inventory-button-width {
        width: 80px !important;
    }

    .dgdg-fi-store-report-top-10-manager {
        height: auto;
    }

    .dgdg-table-filter {
        margin-top: 0px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .dgdg-widget-restored,
    .dgdg-fi-store-report-top-10-manager {
        flex: 0 0 33.33333%;
        /* 3 columns */
        max-width: 33.33333%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .dgdg-widget-restored,
    .dgdg-fi-store-report-top-10-manager {
        flex: 0 0 50%;
        /* 2 columns */
        max-width: 50%;
    }
}

@media (max-width: 767px) {

    .dgdg-widget-restored,
    .dgdg-fi-store-report-top-10-manager {
        flex: 0 0 100%;
        /* 1 column */
        max-width: 100%;
    }
}

/* ---------------------------------------------------
    NEW CAR MERCHANDISING STYLE
--------------------------------------------------- */
.dgdg-merchandising-photo-water-mark {
    font-size: 30px;
    opacity: 0.5;
    transform: rotate(-45deg);
    padding: 175px;
}


.dgdg-new-car-merchandising-action-column {
    min-width: 101px;
}

.dgdg-new-car-merchandising-bg-modified {
    background-color: #ffff64;
}

.dgdg-new-car-merchandising-bg-modified td {
    background-color: #ffff64;
}

.dgdg-new-car-merchandising-button-width {
    width: 160px !important;
}

.dgdg-new-car-merchandising-extra-col {
    background-color: #AEAAAA !important;
    color: #0275d8;
}

.dgdg-new-car-merchandising-missing-col {
    background-color: red !important;
}

.dgdg-new-car-merchandising-cdn-photos-dialog {
    width: 700px;
    margin: 200px auto;
}

.dgdg-new-car-merchandising-cdn-photo {
    margin: auto;
    height: 480px;
    width: 640px;
    margin-block: 5px;
    display: inline-block;
    position: relative;
}

.dgdg-new-car-merchandising-cdn-photo>img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.dgdg-new-car-merchandising-thumbnail-image {
    border: 1px solid black;
    height: 48px;
    width: 64px;
    padding: 0px;
    margin-bottom: 4px;
    margin-left: 4px;
    display: inline-block;
    position: relative;
}


.dgdg-new-car-merchandising-thumbnail-image>img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.dgdg-new-car-merchandising-cdn-photo-nav {
    display: flex;
    align-items: center;
}

.dgdg-new-car-merchandising-cdn-photo-nav>button {
    height: 100% !important;
}

.dgdg-new-car-merchandising-cdn-photo-nav>button>span {
    font-size: 30px;
}

/* ---------------------------------------------------
    NEW PDI STYLE
--------------------------------------------------- */
.dgdg-new-pdi-audit-content {
    min-width: 370px;
}

.dgdg-new-pdi-age {
    width: 70px;
    max-width: 70px;
}

.dgdg-new-pdi-audit-table {
    width: 100%;
    border: 2px solid grey;
}

.dgdg-new-pdi-audit-table td {
    padding: 10px;
    white-space: nowrap;
}

.dgdg-new-pdi-audit-table-column-1 {
    width: 145px;
}

.dgdg-new-pdi-audit-table-column-2 {
    width: 105px;
}

.dgdg-new-pdi-audit-table-column-3 {
    width: 105px;
}

.dgdg-new-pdi-audit-table-column-4 {
    width: 105px;
}

.dgdg-new-pdi-audit-table-data-result {
    text-align: left;
    padding-left: 10px;
}

.dgdg-new-pdi-audit-table-data-wrap {
    text-align: left;
    white-space: normal !important;
    padding-left: 10px;
}

.dgdg-new-pdi-audit-table-cell-title {
    font-weight: bold;
    background-color: #EEEEEE;
    padding-left: 10px;
}

.dgdg-new-pdi-audit-table-row-separator {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: grey;
}

.dgdg-new-pdi-button-width {
    width: clamp(4.375rem, 11.111vw, 10rem) !important;
}

.dgdg-new-pdi-inventory-bg-gray {
    background-color: #808080;
}

.dgdg-new-pdi-inventory-bg-gray td {
    background-color: #808080;
}

.dgdg-new-pdi-inventory-bg-light-blue {
    background-color: lightblue;
}

.dgdg-new-pdi-inventory-bg-light-blue td {
    background-color: lightblue;
}

.dgdg-new-pdi-inventory-bg-light-green {
    background-color: lightgreen;
}

.dgdg-new-pdi-inventory-bg-light-green td {
    background-color: lightgreen;
}

.dgdg-new-pdi-inventory-bg-orange {
    background-color: #ff9f40;
}

.dgdg-new-pdi-inventory-bg-orange td {
    background-color: #ff9f40;
}

.dgdg-new-pdi-inventory-bg-pink {
    background-color: #ffc0cb;
}

.dgdg-new-pdi-inventory-bg-pink td {
    background-color: #ffc0cb;
}

.dgdg-new-pdi-inventory-bg-white {
    background-color: #ffffff;
}

.dgdg-new-pdi-inventory-bg-yellow {
    background-color: #ffff64;
}

.dgdg-new-pdi-inventory-bg-yellow td {
    background-color: #ffff64;
}

.dgdg-new-pdi-inventory-bg-light-purple {
    background-color: #cbc3e3;
}

.dgdg-new-pdi-inventory-bg-light-purple td {
    background-color: #cbc3e3;
}

.dgdg-new-pdi-inventory-model {
    width: 150px;
    max-width: 150px;
}

.dgdg-new-pdi-inventory-ro-number {
    width: 200px;
    max-width: 200px;
}

.dgdg-new-pdi-inventory-sl {
    width: 40px;
    max-width: 40px;
}

.dgdg-new-pdi-inventory-stock-number {
    width: 125px;
    max-width: 125px;
}

.dgdg-new-pdi-inventory-toggle-width {
    width: 200px;
    max-width: 200px;
}

.dgdg-new-pdi-inventory-toggle-width  div {
    flex-flow: row nowrap !important;
}

.dgdg-new-pdi-inventory-vin {
    width: 165px;
    max-width: 165px;
}

.dgdg-new-pdi-inventory-status {
    width: 130px;
    max-width: 130px;
}

.dgdg-new-pdi-inventory-status-override {
    min-width: 140px;
}

.dgdg-new-pdi-legend-container {
    padding-top: 6px;
    white-space: nowrap;
}

.dgdg-new-pdi-store-width {
    width: clamp(4.375rem, 16.32vw, 14.688rem) !important;
}

.dgdg-new-pdi-inventory-reserved {
    min-width: 160px;
}

.dgdg-new-pdi-audit-edit-date {
    margin-top: -12px !important;
}

.dgdg-new-pdi-audit-edit-date-button {
    margin-top: -8px !important;
}

/* ---------------------------------------------------
    ORG CHART STYLE
--------------------------------------------------- */
.dgdg-org-chart-group-summary-table {
    width: 100%;
}

.dgdg-org-chart-group-summary-table tr td {
    padding: 5px;
}

.dgdg-org-chart-table-header {
    background-color: black;
    color: white;
}

.dgdg-org-table {
    width: 100%;
    font-family: Arial;
    font-size: 10px;
}

.dgdg-org-table td {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 2px 5px;
}

/* ---------------------------------------------------
    PARTS DETAILS STYLE
--------------------------------------------------- */
.dgdg-parts-customer-store-dialog {
    width: 1200px;
    margin: 100px auto;
}

.dgdg-parts-detail-dialog {
    width: 1100px;
    margin: 150px auto;
}

.dgdg-parts-detail-widget-restored {
    height: 100%;
    min-height: 400px;
    padding: 0px 0px 0px 15px;
    flex-grow: 1;
}

.dgdg-parts-invoice-dialog {
    width: 600px;
    margin: 200px auto;
}

/* ---------------------------------------------------
    PARTS PAYROLL STYLE
--------------------------------------------------- */
.dgdg-parts-payroll-detail-dialog {
    width: 1400px;
    margin: 200px auto;
}

/* ---------------------------------------------------
    PAYROLL STYLE
--------------------------------------------------- */
.dgdg-payroll {
    background-color: #99d9ea;
    padding: 5px 8px 8px 8px;
    overflow: auto;
    border-radius: 5px;
}

.dgdg-payroll .dgdg-form-label {
    padding: 0px 5px !important;
}

.dgdg-payroll td {
    background-color: #99d9ea;
}

.dgdg-payroll-bg-no-load {
    background-color: #ffffff;
}

.dgdg-payroll-period {
    background-color: #00a2e8;
    margin-bottom: 2px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.dgdg-payroll-period .dgdg-form-label {
    padding: 0px !important;
}

.dgdg-payroll-period .form-group {
    margin: 0px;
}

.dgdg-payroll-period td {
    background-color: #00a2e8;
}

.dgdg-payroll-period td:not(:last-child) {
    border-right: 2px solid #ffffff;
}

.dgdg-payroll-period tr:not(:last-child) {
    border-bottom: 0.5px solid #ffffff;
}

.dgdg-payroll-period-edit {
    background-color: #00a2e8;
    margin-bottom: 2px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.dgdg-payroll-period-edit .dgdg-form-label {
    padding: 0px !important;
}

.dgdg-payroll-period-edit .form-group {
    margin: 0px;
}

.dgdg-payroll-period-edit td {
    background-color: #00a2e8;
    padding: 12px;
    vertical-align: top;
}

.dgdg-payroll-period-edit tr:nth-child(2) {
    border-bottom: 0.5px solid #ffffff;
}

.dgdg-fi-payroll-dialog {
    width: 1200px;
    margin: 250px auto;
}

.dgdg-parts-payroll-dialog {
    width: 1200px;
    margin: 250px auto;
}

.dgdg-sales-payroll-dialog {
    width: 1200px;
    margin: 250px auto;
}

.dgdg-sales-payroll-detail-dialog {
    width: 1350px;
    margin: 200px auto;
}

.dgdg-service-payroll-dialog {
    width: 1200px;
    margin: 250px auto;
}

.dgdg-service-payroll-detail-dialog {
    width: 1400px;
    margin: 200px auto;
}

.dgdg-technician-payroll-dialog {
    width: 1200px;
    margin: 250px auto;
}

.dgdg-payroll-width {
    width: 150px;
}

/* ---------------------------------------------------
    PRODUCT & RESERVE ADJUSTMENTS STYLE
--------------------------------------------------- */
.dgdg-product-reserve-account-detail-dialog {
    width: 1000px;
    margin: 50px auto;
}

.dgdg-product-reserve-add-dialog {
    width: 1100px;
    min-width: 780px;
    margin: 200px auto;
}

.dgdg-product-reserve-add-dialog .dropdown>button {
    white-space: normal;
}

.dgdg-product-reserve-add-dialog .table {
    margin-bottom: 0px;
}

.dgdg-product-reserve-add-dialog .table tr td {
    border-top-width: 0px;
    padding: 5px 10px;
}

.dgdg-product-reserve-bg-total-row {
    background-color: #AEAAAA;
    color: #0275d8;
}

.dgdg-product-reserve-frontend-adjustment-table {
    width: 100%;
}

.dgdg-product-reserve-frontend-adjustment-table td {
    padding: 3px 0px;
}

.dgdg-product-reserve-gl-bg-total-diff {
    background-color: #ffff64 !important;
}

.dgdg-product-reserve-print-content tr td {
    border-bottom: 2px solid #ddd;
}

.dgdg-product-reserve-column-width {
    width: 149px !important;
}

.dgdg-product-reserve-label-width {
    width: 95px !important;
}

/* ---------------------------------------------------
    PSOPS STYLE
--------------------------------------------------- */
.dgdg-psops-content {
    min-width: 340px;
}

.dgdg-psops-products {
    display: inline-grid;
}

.dgdg-psops-products .card {
    display: block !important
}

.dgdg-psops-bg-year-col {
    background-color: #ccffff;
}

.dgdg-psops-bg-other-col {
    background-color: #FFFFFF;
}

/* ---------------------------------------------------
    RO DETAIL STYLE
--------------------------------------------------- */
.dgdg-ro-detail-dialog {
    width: 800px;
    margin: 200px auto;
}

.dgdg-ro-report-bg-white {
    background-color: #ffffff;
}

.dgdg-ro-report-bg-orange {
    background-color: #ff9f40;
}

.dgdg-ro-report-bg-orange td {
    background-color: #ff9f40;
}


/* ---------------------------------------------------
    SALES STORE REPORT STYLE
--------------------------------------------------- */
.dgdg-sales-store-report-content {
    min-width: 340px;
}

.dgdg-sales-store .dgdg-table-v3-row {
    border-top: none;
}

.dgdg-sales-store .dgdg-table-v3-row td {
    border-top: 1px solid #DCDCDC;
    background-color: #FFF;
}


.dgdg-sales-store-bg {
    background-color: #2E77AE !important;
}

.dgdg-sales-store-bg-flag-green {
    background-color: #92d050 !important;
}

.dgdg-sales-store-bg-flag-red {
    background-color: #ff7c80 !important;
}

.dgdg-sales-store-bg-head {
    background-color: #EEEEEE;
}

.dgdg-sales-store-bg-title-foot {
    background-color: #FFFFFF;
}

.dgdg-sales-store-bg-row {
    background-color: #FFFFFF;
}

.dgdg-sales-store-bg-row-alt {
    background-color: #F8F8F8;
}

.dgdg-sales-store-font-gray {
    color: #808080;
}

.dgdg-sales-store-name {
    padding: 5px;
    margin-bottom: -1px;
}

.dgdg-sales-store-name-cell {
    background-color: #F8F8F8;
    padding: 0px;
}

.dgdg-sales-store-red-highlight {
    background-color: #FF9999 !important;
}

.dgdg-sales-store-green-highlight {
    background-color: #92D050 !important;
}

.dgdg-sales-store-night-report-border {
    border-right: 1px solid #DCDCDC !important;
}

@media screen and (max-width: 1600px) {
    .dgdg-sales-store-name {
        padding: 0px;
        display: none;
    }

    .dgdg-sales-store-name-cell {
        background-color: #2E77AE !important;
    }
}

/* ---------------------------------------------------
    SERVICE ADVISOR REPORT STYLE
--------------------------------------------------- */
.dgdg-service-advisor-report-content {
    min-width: 460px;
}

.dgdg-advisor-bg-head {
    background-color: #CECCCC;
}

.dgdg-advisor-detail-menu-not-counted-dialog {
    width: 650px;
    margin: 100px auto;
}

.dgdg-service-advisor-dialog-padding {
    margin-left: 15px;
}

.dgdg-service-advisor-total-commissionable-gross-dialog {
    width: 1300px;
    margin: 100px auto;
}

.dgdg-service-advisor-total-commissionable-gross-table-width {
    width: 97% !important;
}

/* ---------------------------------------------------
    SERVICE STORE REPORT STYLE
--------------------------------------------------- */
.dgdg-service-store-report-content {
    min-width: 330px;
}

.dgdg-service-store-report-bg-head {
    background-color: #CECCCC;
}

.dgdg-service-store-report-loaner-income-header {
    max-width: 65px;
}

.dgdg-service-store-report-bg-total-diff {
    background-color: #ffff64;
}

.dgdg-service-store-report-detail-dialog {
    width: 1000px;
    margin: 50px auto;
}

.dgdg-service-store-report-detail-dialog .tooltip .content {
    transform: translate(-120%, -30%);
}

.dgdg-service-store-report-header-cyan {
    background-color: cyan;
}

.dgdg-service-store-report-header-green {
    background-color: limegreen;
}

.dgdg-service-store-report-header-yellow {
    background-color: yellow;
}

.dgdg-service-store-report-header-white {
    background-color: white;
}

.dgdg-service-store-table {
    width: 1235px !important;
}

/* ---------------------------------------------------
    SERVICE SCHEDULE STYLE
--------------------------------------------------- */
.dgdg-service-schedules-content {
    min-width: 370px;
}

/* ---------------------------------------------------
   STORE STYLE
--------------------------------------------------- */
.dgdg-store-user-map-dialog {
    width: 500px;
    margin: 350px auto;
}

/* ---------------------------------------------------
    TEAM MEMBER PROFILE STYLE
--------------------------------------------------- */
.dgdg-team-member-ddp {
    margin-left: -15px;
}

.dgdg-team-member-title {
    padding-top: 6px;
}

/* ---------------------------------------------------
    TECH SCORE CARD STYLE
--------------------------------------------------- */
.dgdg-tech-score-card-chart {
    height: 500px;
}

.dgdg-tech-score-card-multi-select {
    width: 250px;
}

.dgdg-tech-score-widget {
    width: 100%;
    height: 100%;
}

.dgdg-tech-score-palocity-hours-width {
    width: 140px !important;
}

.dgdg-tech-score-booked-hours-mpi-completed-width {
    width: 140px !important;
}

.dgdg-tech-score-flat-rate-width {
    width: 180px !important;
}

.dgdg-tech-score-ro-count-width {
    width: 87px !important;
}

.dgdg-tech-score-clocked-hours-width {
    width: 163px !important;
}

.dgdg-tech-score-card-columnHelp-width {
    width: 220px;
}

.dgdg-tech-score-card-columnHelp-align-left {
    width: 220px;
    left: calc(100% - 200px) !important;
}

.dgdg-tech-score-card-store-width {
    width: 220px !important;
}

.dgdg-tech-score-card-technician-name-width {
    width: 300px !important;
}

/* ---------------------------------------------------
    TECHNICHIAN PAYROLL STYLE
--------------------------------------------------- */
.dgdg-technician-payroll-detail-dialog {
    width: 1200px;
    margin: 200px auto;
}

.dgdg-tech-payroll-plan-name {
    width: 80px !important
}

.dgdg-technician-payroll-comments {
    width: 1020px;
}
/* ---------------------------------------------------
    US WARRANTY RECONCILIATION STYLE
--------------------------------------------------- */
.dgdg-us-warranty-reconciliation-acc-desc-col {
    min-width: 170px;
}

.dgdg-us-warranty-reconciliation-add-statement-item-dialog {
    width: 1020px;
    margin: 200px auto;
}

.dgdg-us-warranty-reconciliation-add-statement-item-dialog .dropdown>button {
    white-space: normal;
}

.dgdg-us-warranty-reconciliation-add-table {
    width: 100%;
}

.dgdg-us-warranty-reconciliation-add-table td {
    padding: 3px 0px;
}

.dgdg-us-warranty-reconciliation-customer-number-width {
    min-width: 100px !important;
}

.dgdg-us-warranty-reconciliation-extra-col {
    background-color: #AEAAAA !important;
    color: #0275d8;
}

.dgdg-us-warranty-reconciliation-missing-col {
    background-color: red !important;
}

.dgdg-us-warranty-reconciliation-generate-cdk-concatenate-view-dialog {
    width: 1100px;
    margin: 100px auto;
}

.dgdg-us-warranty-reconciliation-journal-entry-details-dialog {
    width: 1000px;
    margin: 100px auto;
}

.dgdg-us-warranty-reconciliation-posting-date-col {
    min-width: 150px;
}

.dgdg-us-warranty-reconciliation-sales-deal-dialog {
    width: 1000px;
    margin: 100px auto;
}

.dgdg-us-warranty-reconciliation-status-no-pay td {
    background-color: #f8cbad;
}

.dgdg-us-warranty-reconciliation-status-others td {
    background-color: #c6e0b4;
}

.dgdg-us-warranty-reconciliation-table-width {
    width: 60% !important;
}

.dgdg-us-warranty-reconciliation-table-height {
    height: 400px;
    overflow: auto;
}

.dgdg-us-warranty-reconciliation-table-cr-cell {
    background-color: black !important;
    color: white !important;
}

.dgdg-us-warranty-reconciliation-table-dr-cell {
    background-color: white !important;
    color: black !important;
}

/* ---------------------------------------------------
    USED CAR MERCHANDISING STYLE
--------------------------------------------------- */
.dgdg-inventory-merch-bg-modified {
    background-color: #ffff64;
}

.dgdg-inventory-merch-bg-modified td {
    background-color: #ffff64;
}

.dgdg-inventory-merch-homenet-state {
    min-width: 150px;
}

.dgdg-used-car-merchandising-action-column {
    min-width: 101px;
}

.dgdg-used-car-merchandising-extra-col {
    background-color: #AEAAAA !important;
    color: #0275d8;
}

.dgdg-used-car-merchandising-missing-col {
    background-color: red !important;
}

.dgdg-used-car-merchandising-vehicle-type-col {
    min-width: 155px !important;
}

.dgdg-used-car-merchandising-cdn-photos-dialog {
    width: 700px;
    margin: 200px auto;
}

.dgdg-used-car-merchandising-cdn-photo {
    margin: auto;
    height: 480px;
    width: 640px;
    margin-block: 5px;
    display: inline-block;
    position: relative;
}

.dgdg-used-car-merchandising-cdn-photo>img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.dgdg-used-car-merchandising-thumbnail-image {
    border: 1px solid black;
    height: 48px;
    width: 64px;
    padding: 0px;
    margin-bottom: 4px;
    margin-left: 4px;
    display: inline-block;
    position: relative;
}


.dgdg-used-car-merchandising-thumbnail-image>img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.dgdg-used-car-merchandising-cdn-photo-nav {
    display: flex;
    align-items: center;
}

.dgdg-used-car-merchandising-cdn-photo-nav>button {
    height: 100% !important;
}

.dgdg-used-car-merchandising-cdn-photo-nav>button>span {
    font-size: 30px;
}

/* ---------------------------------------------------
    USED RECONDITIONING STYLE
--------------------------------------------------- */
.dgdg-used-reconditioning-content {
    min-width: 420px;
}

.dgdg-used-reconditioning-audit-table {
    width: 100%;
    margin-left: 5px;
    border: 2px solid grey;
}

.dgdg-used-reconditioning-audit-table td {
    padding: 10px;
    white-space: nowrap;
}

.dgdg-used-reconditioning-audit-table-cell-title {
    font-weight: bold;
    background-color: #EEEEEE;
    padding-left: 10px;
}

.dgdg-used-reconditioning-audit-table-column-1 {
    width: 145px;
}

.dgdg-used-reconditioning-audit-table-column-2 {
    width: 150px;
}

.dgdg-used-reconditioning-audit-table-column-3 {
    width: 135px;
}

.dgdg-used-reconditioning-audit-table-column-4 {
    width: 90px;
}

.dgdg-used-reconditioning-audit-table-data-result {
    text-align: left;
    padding-left: 10px;
}

.dgdg-used-reconditioning-audit-table-data-wrap {
    text-align: left;
    white-space: normal !important;
    padding-left: 10px;
}

.dgdg-used-reconditioning-audit-table-row-separator {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: grey;
}

.dgdg-used-reconditioning-audit-tooltip {
    margin-top: 10px;
    width: 300px;
}

.dgdg-used-reconditioning-bg-orange {
    background-color: #ff9f40;
}

.dgdg-used-reconditioning-bg-orange td {
    background-color: #ff9f40;
}

.dgdg-used-reconditioning-bg-gray {
    background-color: #808080;
}

.dgdg-used-reconditioning-bg-gray td {
    background-color: #808080;
}

.dgdg-used-reconditioning-body-hold-toggle {
    margin-left: -15px !important;
}

.dgdg-used-reconditioning-button-width {
    width: clamp(4.375rem, 11.111vw, 10rem) !important;
}

.dgdg-used-reconditioning-calendar-button {
    padding: 0px !important;
    border: none !important;
    background-color: transparent !important;
}

.dgdg-used-reconditioning-calendar-icon {
    float: right;
    padding-left: 10px;
}

.dgdg-used-reconditioning-column {
    min-width: 100px;
}

.dgdg-used-reconditioning-label {
    font-size: 11px;
}

.dgdg-used-reconditioning-service-out {
    min-width: 240px;
}

.dgdg-used-reconditioning-table td {
    border-right: none !important;
}

.dgdg-used-reconditioning-toggle-yellow .custom-control-input:checked~.custom-control-label::before {
    border-color: #ffff00 !important;
    background-color: #ffff00 !important;
}

.dgdg-used-reconditioning-toggle-yellow .custom-control-input:checked~.custom-control-label::after {
    background-color: #007BFF !important;
}

.dgdg-used-reconditioning-undo-button {
    float: right;
    padding-left: 10px;
    padding-bottom: 10px;
}

.dgdg-used-reconditioning-publish {
    min-width: 150px;
}

.dgdg-used-reconditioning-wholesale {
    min-width: 120px;
}

.dgdg-used-reconditioning-cpo {
    min-width: 120px;
}

.dgdg-used-reconditioning-estimated-date {
    background-color: #ffff64 !important;
}

.dgdg-used-reconditioning-summary-detail-dialog {
    width: 1120px;
    margin: 50px auto;
}

.dgdg-used-reconditioning-audit-edit-date {
    margin-top: -12px !important;
}

.dgdg-used-reconditioning-audit-edit-date-button {
    margin-top: -8px !important;
}

.dgdg-used-reconditioning-audit-undo-button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    float: right;
}

/* ---------------------------------------------------
    VIN SOLUTION LEGACY NOTES
--------------------------------------------------- */
.dgdg-vin-solutions-legacy-notes-note-date-col {
    width: 85px;
}

.dgdg-vin-solutions-legacy-notes-note-type-col {
    width: 150px;
}

.dgdg-vin-solutions-legacy-notes-note-subject-col {
    width: 200px;
}

.dgdg-vin-solutions-legacy-notes-text-date-col {
    width: 85px;
}

.dgdg-vin-solutions-legacy-notes-text-dir-col {
    width: 120px;
}

/* ---------------------------------------------------
    VIN SOLUTION STYLE
--------------------------------------------------- */
.dgdg-vin-remove-map-width {
    width: 110px;
}

.dgdg-vin-user-map-dialog {
    width: 400px;
    margin: 350px auto;
}

/* ---------------------------------------------------
    WHOLESALE STYLE
--------------------------------------------------- */
.dgdg-wholesale-yoy-bg-row-alt {
    background-color: #F8F8F8;
}

.dgdg-wholesale-yoy-card-maximized {
    height: 100%;
}

.dgdg-wholesale-yoy-widget-maximized {
    width: 100%;
    height: calc(100% - 35px);
}

.dgdg-wholesale-yoy-widget-restored {
    width: 100%;
    padding-bottom: 0px;
    height: 500px !important;
}

/* ---------------------------------------------------
    INTER-COMPANY BALANCES
--------------------------------------------------- */
.dgdg-inter-company-balances-help-card {
    height: 100%;
    width: 40%;
    margin-left: 5px;
}

.dgdg-inter-company-balances-table-cr-cell {
    background-color: black !important;
    color: white !important;
}

.dgdg-inter-company-balances-table-dr-cell {
    background-color: white !important;
    color: black !important;
}

/* ---------------------------------------------------
    DIRECTORY
--------------------------------------------------- */
.dgdg-directory-action-column {
    text-align: center;
}

.dgdg-directory-action-column .btn-link:not(:disabled) {
    color: #7C51FA !important;
}

.dgdg-directory-page-dialog {
    width: 450px;
    margin: 100px auto;
}

.dgdg-directory-header {
    padding: 10px;
    margin-left: 6px;
}

.dgdg-directory-dailpad-layout {
    width: 100%;
    border-collapse: inherit !important;
    border-spacing: 10px;
}

.dgdg-directory-dail {
    width: 33.33%;
}

.dgdg-directory-caller {
    font-size: 20px !important;
}

.dgdg-directory-call {
    padding: 8px;
}

.dgdg-directory-call button {
    font-size: 25px !important;
    height: 54px !important;
}

.dgdg-directory-dail-number {
    width: 100%;
    height: 70px !important;
    font-weight: 500;
    font-size: 25px !important;
}

.dgdg-directory-alphabet {
    font-size: 13px;
    letter-spacing: 1px;
}

.dgdg-directory-userSearch .input-group input {
    height: 48px !important;
    font-size: 25px !important;
}

.dgdg-directory-userSearch .input-group-append .btn {
    height: 48px !important;
}

.dgdg-directory-userSearch .dropdown-item {
    font-size: 20px !important;
}

.dgdg-directory-legend-container {
    padding-top: 6px;
    white-space: nowrap;
}

.dgdg-directory-dnd {
    min-width: 100px;
}

.dgdg-directory-dnd .col-form-label {
    justify-content: center !important;
}

.dgdg-directory-phone {
    min-width: 85px;
}

.dgdg-directory-user-search {
    margin-left: 16px;
    margin-right: 16px;
}

.dgdg-directory-bg-white {
    background-color: #ffffff !important;
}

.dgdg-directory-help-card {
    height: 100%;
    min-width: 450px;
    margin-left: 5px;

}

.dgdg-directory-help-card-block a {
    margin-top: 10px;
    margin-left: 5px;
}

.dgdg-new-pdi-inventory-bg-white td {
    background-color: #ffffff !important;
}

.dgdg-directory-bg-yellow {
    background-color: #ffff64;
}

.dgdg-directory-bg-yellow td {
    background-color: #ffff64;
}

.dgdg-directory-bg-green {
    background-color: lightgreen;
}

.dgdg-directory-bg-green td {
    background-color: lightgreen;
}

.dgdg-directory-bg-blue {
    background-color: lightblue !important;
}

.dgdg-directory-bg-blue td {
    background-color: lightblue !important;
}

.dgdg-directory-bg-cyan {
    background-color: lightcyan !important;
}

.dgdg-directory-bg-cyan td {
    background-color: lightcyan !important;
}

.dgdg-directory-bg-coral {
    background-color: lightcoral !important;
}

.dgdg-directory-bg-coral td {
    background-color: lightcoral !important;
}

.dgdg-directory-bg-pink {
    background-color: lightpink !important;
}

.dgdg-directory-bg-pink td {
    background-color: lightpink !important;
}

.dgdg-directory-bg-honeydew {
    background-color: #f0fff0 !important;
}

.dgdg-directory-bg-honeydew td {
    background-color: #f0fff0 !important;
}

.dgdg-directory-bg-yellow {
    background-color: lightyellow !important;
}

.dgdg-directory-bg-yellow td {
    background-color: lightyellow !important;
}

.dgdg-directory-bg-metalic-green {
    background-color: #7c9d8e !important;
}

.dgdg-directory-bg-metalic-green td {
    background-color: #7c9d8e !important;
}

.dgdg-directory-bg-lavender {
    background-color: #e6e6fa !important;
}

.dgdg-directory-bg-lavender td {
    background-color: #e6e6fa !important;
}

.dgdg-directory-bg-peachpuff {
    background-color: #ffdab9 !important;
}

.dgdg-directory-bg-peachpuff td {
    background-color: #ffdab9 !important;
}

.dgdg-directory-bg-plum {
    background-color: #ffbbff !important;
}

.dgdg-directory-bg-plum td {
    background-color: #ffbbff !important;
}

.dgdg-directory-bg-silver-pink {
    background-color: #c4aead !important;
}

.dgdg-directory-bg-silver-pink td {
    background-color: #c4aead !important;
}

.dgdg-directory-bg-mistyrose {
    background-color: #fFE4E1 !important;
}

.dgdg-directory-bg-mistyrose td {
    background-color: #fFE4E1 !important;
}

.dgdg-directory-bg-mint {
    background-color: #aaf0d1 !important;
}

.dgdg-directory-bg-mint td {
    background-color: #aaf0d1 !important;
}

.dgdg-directory-bg-mint {
    background-color: #aaf0d1 !important;
}

.dgdg-directory-bg-mint td {
    background-color: #aaf0d1 !important;
}

.dgdg-directory-bg-mustard-yellow {
    background-color: #ffdb58 !important;
}

.dgdg-directory-bg--mustard-yellow td {
    background-color: #ffdb58 !important;
}

.dgdg-directory-bg-light-yellow {
    background-color: #e3f9a6 !important;
}

.dgdg-directory-bg-light-yellow td {
    background-color: #e3f9a6 !important;
}

.dgdg-directory-bg-sage {
    background-color: #bcb88a !important;
}

.dgdg-directory-bg-sage td {
    background-color: #bcb88a !important;
}

.dgdg-directory-bg-thistle {
    background-color: #ffe1ff !important;
}

.dgdg-directory-bg-thistle td {
    background-color: #ffe1ff !important;
}

.dgdg-directory-bg-gold {
    background-color: #c9ae5d !important;
}

.dgdg-directory-bg-gold td {
    background-color: #c9ae5d !important;
}

.dgdg-directory-bg-purple {
    background-color: #ab82ff !important;
}

.dgdg-directory-bg-purple td {
    background-color: #ab82ff !important;
}

.dgdg-directory-bg-yellow-chalk {
    background-color: #f9f8a6 !important;
}

.dgdg-directory-bg-yellow-chalk td {
    background-color: #f9f8a6 !important;
}

.dgdg-directory-bg-orange {
    background-color: orange !important;
}

.dgdg-directory-bg-orange td {
    background-color: orange !important;
}

.dgdg-directory-employee-edit-dialog {
    width: 1150px;
    margin: 200px auto;
}

.dgdg-directory-employee-edit-employee-column {
    width: 425px !important;
}

.dgdg-directory-employee-edit-store-column {
    width: 200px !important;
}

.dgdg-directory-employee-edit-extension-column {
    width: 140px !important;
}

.dgdg-directory-employee-action-column {
    text-align: center;
}

.dgdg-directory-employee-bg-red {
    background-color: #CD5C5C !important;
}

.dgdg-directory-employee-bg-red td {
    background-color: #CD5C5C !important;
}

.dgdg-directory-employee-status-column {
    text-align: center;
}

/* ---------------------------------------------------
    ONBOARDING
--------------------------------------------------- */
.dgdg-onboarding-add-dialog {
    width: 900px;
    margin: 200px auto;
}

.dgdg-onboarding-table {
    width: 100%;
}

.dgdg-onboarding-table td {
    padding: 3px 0px;
}

.dgdg-onboarding-password-eye::-ms-reveal {
    display: none;
}

.dgdg-onboarding-password {
    -webkit-text-security: disc;
    color: #495057;
}

/* ---------------------------------------------------
    CALCULATE MMR
--------------------------------------------------- */

.dgdg-calcuate-mmr-input-width {
    width: 140px !important;
}

.dgdg-calcuate-mmr-api-response-dialog {
    width: calc(100% - 400px);
    margin: 50px auto;
}

.dgdg-calcuate-mmr-api-response-textbox-width {
    width: 100% !important;
}

.dgdg-calculate-mmr-highlight-bestmatch td,
.dgdg-calculate-mmr-best-match {
    background-color: yellow;
}

/* ---------------------------------------------------
    REPORTS
--------------------------------------------------- */
.dgdg-reports {
    width: 400px;
    padding: 5px 10px 0px 0px;
    overflow: hidden;
}

.dgdg-widget-reports-header {
    display: flex;
    align-items: center;
}

.dgdg-widget-reports-toggle {
    margin-left: 20px;
}

.dgdg-widget-reports-header .form-group {
    margin-bottom: 0px !important;
}

.dgdg-reports-card {
    height: 100%;
    overflow: auto;
}

.dgdg-reports-card .card-header {
    display: flex;
    align-items: center;
}

.dgdg-reports-table {}

.dgdg-reports-table-row {
    background-color: lightgray !important;
}

.dgdg-reports-table-row td {
    background-color: lightgray !important;
}

/* ---------------------------------------------------
    MANAGE REPORTS
--------------------------------------------------- */

.dgdg-manage-report {
    display: inline-grid;
}

.dgdg-manage-report-metadata-dialog {
    width: calc(100% - 200px);
    margin: 50px auto;
}

.dgdg-manage-report-metadata-textbox-width {
    width: 100% !important;
}

.dgdg-manage-report-font-color {
    color:gray;
}

.dgdg-manage-report-header-color {
    background-color: lightGrey !important;
}

.dgdg-manage-report-role-dialog{
    width: 635px;
    margin: 90px auto;
}

.dgdg-manage-report-role-column-width {
    width: 245px !important;
}

/* ---------------------------------------------------
    OPD Sales
--------------------------------------------------- */
.dgdg-opd-sales-content {
    min-width: 300px !important;
}

.dgdg-opd-sales-edit-dialog {
    width: 830px;
    margin: 200px auto;
    min-width: 600px;
}

.dgdg-opd-sales-team-result-edit-dialog {
    width: 850px;
    margin: 200px auto;
}

.dgdg-opd-sales-link-column-padding {
    padding: 0px !important;
}

.dgdg-opd-sales-table {
    width: 100%;
}

.dgdg-opd-sales-add-employee-width {
    width: 550px !important;
}

.dgdg-opd-sales-add-employee-table-width {
    width: 100% !important;
}

.dgdg-opd-sales-column-width {
    min-width: 90px !important;
    text-align: left !important;
}

.dgdg-opd-sales-edit-volume-width {
    width: 100px !important;
}

.dgdg-opd-sales-edit-closing-width {
    width: 125px !important;
}

.dgdg-opd-sales-edit-volume-width,.dgdg-opd-sales-edit-closing-width 
.input-group-append {
    text-indent: 0px !important;
    margin-left: -3px !important;
}

.dgdg-opd-sales-month:not(:last-child) {
    border-right: 1px dotted gray;
}

.dgdg-opd-sales-month-level-1 {
    background-color:#DBF2DC !important;
}

.dgdg-opd-sales-month-level-1 td {
    background-color: #DBF2DC !important;
}

.dgdg-opd-sales-month-level-2 {
    background-color: #ACE1AF !important;
}

.dgdg-opd-sales-month-level-2 td {
    background-color: #ACE1AF !important;
}

.dgdg-opd-sales-month-level-3 {
    background-color: #4EBF55 !important;
    color: white;
}

.dgdg-opd-sales-month-level-3 td {
    background-color: #4EBF55 !important;
    color: white;
}

.dgdg-opd-sales-month-level-4 {
    background-color: #37973C !important;
    color: white;
}

.dgdg-opd-sales-month-level-4 td {
    background-color: #37973C !important;
    color: white;
}

/* ---------------------------------------------------
    MISSING TITLES
--------------------------------------------------- */
.dgdg-missing-title-sold-color {
    background-color: lightcoral;
}
.dgdg-missing-title-sold-color td {
    background-color: lightcoral;
}

.dgdg-missing-title-status-bg-resolved {
    background-color: lightgreen;
}

.dgdg-missing-title-status-bg-resolved td {
    background-color: lightgreen;
}

.dgdg-missing-title-status-bg-closed {
    background-color: rgb(192, 192, 192);
}

.dgdg-missing-title-status-bg-closed td {
    background-color: rgb(192, 192, 192);
}

.dgdg-missing-title-col-status {
    min-width: 125px !important;
}

.dgdg-missing-title-edit-dialog {
    width: 1020px;
    min-width: 400px;
    margin: 200px auto;
}

.dgdg-missing-title-summary-gt-10 {
    background-color: #ffff64;
}

.dgdg-missing-title-summary-gt-20 {
    background-color: #00ccff;
}

.dgdg-missing-title-summary-gt-30 {
    background-color: red;
}

.dgdg-missing-title-summary-total {
    background-color: #000000;
    color: white;
}

.dgdg-missing-title-summary-number-column {
    text-align: center !important;
}

/* ---------------------------------------------------
    DGDG Data
--------------------------------------------------- */
.dgdg-data-table {
    width: 100%;
    font-family: Arial;
    font-size: 12px;
}

.dgdg-data-table td {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 2px 5px;
}

.dgdg-data-table-header {
    font-size: 12px;
    font-weight: bold;
}

/* ---------------------------------------------------
    UI SCALING
--------------------------------------------------- */
.dgdg-responsive-page {
    /* Responsive css */
    /* Tabs/Badge/Query/Body */
    /* Page css */

    label,
    input::placeholder,
    .form-control,
    .dgdg-badge,
    .dgdg-site-scrollable-content,
    .modal-content,
    .btn,
    .input-group-text {
        font-size: clamp(0.438rem, 0.903vw, 0.813rem) !important;
    }

    input,
    .input-group-append,
    .input-group-prepend {
        height: clamp(1.563rem, 2.5vw, 2.25rem) !important;
    }

    .input-group .col-form-label {
        min-width: clamp(4.063rem, 10.417vw, 9.375rem) !important;
    }

    .btn {
        height: auto !important;
    }

    .input-group-text,
    .btn-primary {
        padding: clamp(0.125rem, 0.417vw, 0.375rem) clamp(0.25rem, 0.833vw, 0.75rem) !important;
        min-width: auto !important;
    }

    .dgdg-table-filter-text {
        margin-top: -6px;
    }

    .nav-link,
    .dgdg-quick-link {
        padding: clamp(0.25rem, 0.556vw, 0.5rem) clamp(0.5rem, 1.111vw, 1rem);
        min-width: auto !important;
    }

    .form-inline .btn-primary {
        margin-left: 10px !important;
    }

    .custom-switch .custom-control-label::before {
        width: clamp(1rem, 1.806vw, 1.625rem) !important;
        height: clamp(0.625rem, 1.111vw, 1rem) !important;
    }

    .custom-switch .custom-control-label::after,
    .custom-radio .custom-control-label::after {
        width: clamp(0.375rem, 0.833vw, 0.75rem) !important;
        height: clamp(0.375rem, 0.833vw, 0.75rem) !important;
    }

    .custom-radio .custom-control-label::before {
        width: clamp(0.438rem, 0.972vw, 0.875rem) !important;
        height: clamp(0.438rem, 0.972vw, 0.875rem) !important;
    }

    .dropdown-range-control {
        width: clamp(4.063rem, 10.417vw, 9.375rem);
    }

    .dgdg-date {
        width: clamp(5rem, 9.722vw, 8.75rem) !important;
    }

    .card-header {
        padding: clamp(0.25rem, 0.833vw, 0.75rem) clamp(0.5rem, 1.389vw, 1.25rem) !important;
    }

    .fa-window-close {
        font-size: clamp(0.625rem, 1.25vw, 1.125rem) !important;
        vertical-align: middle;
    }

    .dgdg-cit-acc-desc-col {
        min-width: clamp(6.25rem,11.806vw,10.625rem) !important
    }

    .dgdg-cit-cash-down-col {
        min-width: clamp(3.125rem,5.556vw, 5rem) !important
    }

    .dgdg-table-v3 td {
        padding : clamp(0.063rem,0.348vw, 0.313rem) clamp(0.125rem,0.417vw, 0.375rem) !important
    }

    /* Compensate for bootstrap which is switching control's label from inline to break */
    @media (max-width: 575px) {
        .form-inline .form-group {
            display: flex !important;
            margin: 0px;
            padding-left: 5px;
            margin-top: 5px;
        }

        .dgdg-form-label {
            margin: auto;
            text-wrap: nowrap;
        }

        .dgdg-demo-page-date,
        .dgdg-fi-store-report-date,
        .form-group:first-child .dgdg-date {
            margin-right: 5px !important;
        }

        .btn-primary {
            margin-top: 5px !important;
        }

        .dgdg-modal-dialog-header .btn-primary {
            margin-top: 0px !important;
        }
    }

    .dgdg-service-schedule-query-status,
    .dgdg-service-schedule-status .input-group {
        min-width: clamp(9.375rem, 14.583vw, 15rem) !important;
    }
}